<template>
  <Title :text="t(`t1`)" />
  <div class="grid-column grid-column_span_5">
    <Promo
      class="promo_full_width"
      :src-pic="require(`@/assets/cover/science.jpg`)"
      :src-pic-mobile="require(`@/assets/cover/m/science.jpg`)"
    />
  </div>

  <div class="grid-column">
    <Share :title="t(`t1`)" />
  </div>
  <div class="grid-column grid-column_span_4 grid-column_row_2">
    <div class="h2-1" v-html="t(`t2`)"></div>
  </div>

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>

  <div v-if="isMobile" class="grid-line"></div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import Title from "@/components/Title.vue";
import Promo from "@/components/Promo.vue";
import Share from "@/components/Share.vue";
import News from "@/components/News.vue";

export default defineComponent({
  name: "Science",
  components: {
    Title,
    Promo,
    Share,
    News,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
});
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "t1": "SCIENCE",
    "t2": "<p>In science areas, the Office acts as a driver of academic and research communication and relations between the main scientific players of the countries.</p><p>Priority fields of research projects</p><ul><li>Genetics</li><li>Oil and Gas</li><li>Arabic writing</li><li>Nature and environment</li><li>Food sustainability</li></ul>",
  },
  "ru": {
    "t1": "НАУКА",
    "t2": "<p>В области науки OKI стимулирует научно-исследовательскую коммуникацию между основными научными центрами обеих стран.</p><p>Приоритетные направления научных исследований</p><ul><li>Генетика</li><li>Нефть и газ</li><li>Арабская письменность</li><li>Природа и окружающая среда</li><li>Продовольственная устойчивость</li></ul>",
  },
  "ar": {
    "t1": "العلوم",
    "t2": "<p>في مجال العلوم، يحفز مكتب المعرفة والابتكار الاتصال البحثي بين مراكز البحث الرئيسية في كلا البلدين.</p><p>مجالات البحث ذات الأولوية</p><ul><li>علم الوراثة</li><li>النفط والغاز</li><li>الكتابة العربية</li><li>الطبيعة والبيئة</li><li>استدامة الغذاء</li></ul>",
  }
}
</i18n>
