<template>
  <div class="button-group">
    <p v-if="isMobile" class="color-grey">{{ t("Share1") }}</p>
    <Button msg="FB" @click="onShareFb" />
    <Button msg="TW" @click="onShareTw" />
    <Button msg="VK" @click="onShareVk" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import Button from "@/components/Button.vue";

export default defineComponent({
  name: "Share",
  components: {
    Button,
  },
  props: {
    title: String,
    image: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t, locale };
  },
  methods: {
    onShareFb() {
      window.open(
        `https://www.facebook.com/sharer.php?u=${location.href}&t=${this.title}`
      );
    },
    onShareTw() {
      window.open(
        `https://twitter.com/share?url=${location.href}&text=${this.title}`
      );
    },
    onShareVk() {
      window.open(
        `https://vk.com/share.php?url=${location.href}&title=${this.title}${
          this.image ? `&image=${this.image}` : ""
        }`
      );
    },
  },
});
</script>

<i18n>
{
  "en": {
    "Share1": "Share",
  },
  "ru": {
    "Share1": "Поделиться",
  },
  "ar": {
    "Share1": "شارك",
  }
}
</i18n>
