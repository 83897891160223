<template>
  <div class="grid-column grid-column_span_4">
    <div v-if="isMobile" class="pm-18"></div>
    <h2 class="h2 upper">{{ text }}</h2>
  </div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.h2 {
  word-break: break-word;
}
</style>
